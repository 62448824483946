<template>
    <div>
        <div class="md-layout">
            <md-card v-show="payments.length > 0 && !loading && !downloadLoading">
                <md-table class="md-table-mobile-padding">
                    <md-table-row>
                        <md-table-head v-if="!mobile">Transaction ID</md-table-head>
                        <md-table-head>Amount</md-table-head>
                        <md-table-head>Status</md-table-head>
                        <md-table-head>Date</md-table-head>
                        <md-table-head v-if="!mobile">View</md-table-head>
                        <md-table-head>Download</md-table-head>
                    </md-table-row>

                    <md-table-row v-for="payment in this.paginationTransactions" :key="payment.id">
                        <md-table-cell v-if="!mobile">#{{payment | maskId}}</md-table-cell>
                        <md-table-cell>{{payment.amount | formatPrice}}</md-table-cell>
                        <md-table-cell>{{payment | paymentStatus}}</md-table-cell>
                        <md-table-cell>{{payment.date | formatDate}}</md-table-cell>
                        <md-table-cell class="url" v-if="!mobile">
                            <span title="View invoice" @click="$router.push({name: 'settings.payment-receipt', params: {paymentId: payment.id}})"><md-icon>zoom_in</md-icon></span>
                        </md-table-cell>
                        <md-table-cell class="url">
                            <span
                                    title="Download invoice"
                                    @click="download(payment)"
                            ><md-icon>archive</md-icon></span>
                        </md-table-cell>
                    </md-table-row>
                </md-table>

                <!-- PAGINATION BUTTONS -->
                <div class="md-layout" v-if="payments.length > paginationLimit">
                    <div class="md-layout-item table-nav">
                        <md-button
                            class="md-raised md-primary"
                            :disabled="currentPage === 1"
                            @click="previousPage"
                        >Previous</md-button>
                        <md-button
                            class="md-raised md-primary"
                            :disabled="nextDisabled"
                            @click="nextPage"
                        >Next</md-button>
                    </div>
                </div>

            </md-card>
                <div class="notice" v-show="payments.length == 0 && !loading">
                    <transactions-zero></transactions-zero>
                </div>
            <md-progress-spinner md-mode="indeterminate" class="spinner" v-show="(loading && payments.length == 0) || downloadLoading"></md-progress-spinner>
        </div>      
    </div>
</template>

<script>
    import {mapActions} from 'vuex'
    import {format} from 'date-fns'
    import TransactionsZero from "@/components/Cards/TransactionsZero";

    export default {
        name: "TransactionList",
        components: {TransactionsZero},
        data() {
            return {
                payments: [],
                loading: true,
                mobile: false,
                downloadLoading: false,
                paginationLimit: 15,
                currentPage: 1,
            }
        },
        computed: {
            paginationTransactions() {
                const start = this.paginationLimit * (this.currentPage - 1);
                const end = start + this.paginationLimit;
                return this.payments.slice(start, end)
            },
            nextDisabled() {
                if(this.currentPage === Math.ceil(this.payments.length / this.paginationLimit)){
                  return true;
                }
                return false;
            }
        },
        filters: {
            formatDate(date){
                return format(date, 'MM/DD/YYYY')
            },
            formatPrice(value) {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                })
                return formatter.format(value)
            },
            maskId(transaction){ // ADD A PORTION OF THE REVERSED UNIX DATESTAMP TO THE ID SO THAT IT IS 6 CHARACTERS LONG.
                const unixDate = Math.round((new Date(transaction.date)).getTime() / 1000);
                const idLength = `${transaction.id}`.length;
                const additionalCharactersNeeded = 6 - idLength;
                const stringUnix = ("" + unixDate).split("").reverse().join("");
                return `${stringUnix.substring(1, additionalCharactersNeeded + 1)}${transaction.id}`
            },

            paymentStatus(payment) {
                if(payment.success == 1){
                    return "Paid"
                }else if(payment.success == 2){
                    return "Retry in progress"
                }else if(payment.success == 5){
                    return "Failed"
                } else if ((!payment.paymentTerm || (payment.paymentTerm && payment.paymentTerm.slug === 'receipt')) && payment.success == 0) {
                    return "Failed"
                } else if (payment.paymentTerm && payment.paymentTerm.slug.includes('invoice')){
                    return "Invoice Sent"
                }
            }
        },
        async created(){
            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }
            this.payments = await this.$store.dispatch('getPayments');
            this.loading = false
        },
        mounted(){
            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })  
        },
        methods: {
            ...mapActions(["downloadReceipt"]),

            async download(payment) {
                this.downloadLoading = true;
                await this.downloadReceipt(payment)
                this.downloadLoading = false;
            },

            previousPage() {
                if(this.currentPage > 1){
                  this.currentPage--
                }
            },

            nextPage() {
                this.currentPage++;
            }
        }
    }
</script>

<style lang="scss" scoped>
    span {
      width: 100%;
      height: 100%;
      display: block;
      background: md-get-palette-color(blue, 200);
    }
    .notice{
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        .title{
            font-size: 22px;
            font-weight: 500;
        }
        .intro{
            font-size: 16px;
            font-weight: 300;
        }
        span{
            height: unset;
        }
    }
    .url{
        cursor: pointer;
    }
    .text-center{
        text-align: center;
    }
    .md-title{
        margin-bottom: 20px;
    }
    .md-app-content .md-card{
        margin-left: 0px;
        width: 100%;
        margin-right: 0px;
    }
    .md-app-content {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding: 0px;
    }

      .spinner{
        margin-left: auto;
        margin-right: auto;
        margin-top: 18%;
        position: absolute;
        left:50%;
        margin-left: -40px;
    }
    .table-nav {
      text-align: right;
      margin-top: 25px;
      margin-bottom: 25px;
      padding-right: 22px
    }
</style>